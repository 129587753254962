import { toast, ToastPosition } from 'react-toastify';
import { isTest } from '../IsTest';

const autoClose = isTest ? 1000 : 3000;
const animationDuration = isTest ? '0s' : '0.5s';

export const showSuccessNotification = (message: string) => {
  toast.success(message, {
    position: 'top-right',
    autoClose,
    theme: 'colored',
  });
};

export const showPrimaryNotification = (message: string) => {
  toast(message, {
    position: 'top-right',
    hideProgressBar: true,
    autoClose,
    style: {
      backgroundColor: 'rgb(0, 99, 211)',
      color: 'white',
      fontSize: '16px',
    },
    closeButton: false,
  });
};

export const showWeatherConfirmationNotification = (message: string) => {
  toast.info(message, {
    position: 'top-center',
    hideProgressBar: true,
    autoClose,
    style: {
      backgroundColor: 'rgb(0, 99, 211)',
      color: 'white',
      fontSize: '16px',
    },
    closeButton: false,
  });
};

export const showWeatherErrorNotification = (message: string) => {
  toast.error(message, {
    position: 'top-center',
    hideProgressBar: true,
    autoClose,
    style: {
      backgroundColor: 'rgb(0, 99, 211)',
      color: 'white',
      fontSize: '16px',
    },
    closeButton: false,
  });
};

export const showErrorNotification = (
  message: string,
  position?: ToastPosition
) => {
  toast.error(message, {
    position: position ?? 'top-right',
    theme: 'colored',
    closeOnClick: true,
    autoClose,
    style: {
      animationDuration,
    },
  });
};
