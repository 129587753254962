import { Suspense, useCallback, useEffect } from 'react';
import { useLocation, useNavigate, useRoutes } from 'react-router-dom';
import { getIndexPath as getKPIOverviewPath } from '~/features/analysis/kpi-overview/routes';
import { getLoginPath } from '~/features/auth/routes';
import { getIndexPath as getProVersionPagePath } from '~/features/buy-pro-version/routes';
import { LoadingText } from '~/features/common/components/LoadingText';
import { useAuth } from '~/features/common/context/AuthContext';
import { getIndexPath as getPartsPath } from '~/features/parts/routes';
import { getIndexPath as getSimulationPath } from '~/features/simulation/routes';
import {
  getTimeSheetExportPath,
  getTimeTrackerAdminPath,
  getIndexPath as getTimeTrackerPath,
} from '~/features/timetracker/routes';
// @ts-expect-error types are ignore because of the dynamic imports
import routes from '~react-pages';
import { navigationShortcutList } from './features/common/components/Sidebar/components/SidebarNavigationLinks';
import { isTest } from './features/common/utils/IsTest';
import { getRole } from './features/common/utils/loginChecks';
import {
  EnumNavStatus,
  restrictRoutesAndFeatures,
} from './features/common/utils/security/ResrictRoutesAndFeatures';
import { showErrorNotification } from './features/common/utils/services/notificationService';
import { EnumPersonRole } from './generated/graphql';
import { routeIsAllowed } from './graphql/fetcher';

const disallowedRoutes = [
  getKPIOverviewPath({}),
  getSimulationPath({}),
  getTimeTrackerPath({}),
  getPartsPath({}),
  getTimeSheetExportPath({}),
  getTimeTrackerAdminPath({}),
];

export const RenderRoutes = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const myRole = getRole();

  const handleKeyPress = useCallback(
    (event: { shiftKey: boolean; ctrlKey: boolean; key: string | number }) => {
      if (event.shiftKey && event.ctrlKey) {
        const returnPathFunction = navigationShortcutList[event.key];
        if (returnPathFunction) {
          navigate(returnPathFunction({}));
        }
      }
    },
    []
  );

  useEffect(() => {
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [handleKeyPress]);

  useEffect(() => {
    // we check that the user is logged in because when a user logs out the notification is sent because of how we handle logout, and we don't want it in this case
    if (auth.isLoggedIn) {
      const navStatus = restrictRoutesAndFeatures(location, myRole);

      // if user is an admin and route isn't found or for some reason it's restricted by the route lookup then don't restrict navigation
      if (myRole === EnumPersonRole.ADMIN) {
        if (navStatus === EnumNavStatus.NOT_FOUND) {
          showErrorNotification("This route doesn't exist");
          return;
        }
        if (navStatus === EnumNavStatus.DENIED) {
          return;
        }
      }

      // warn the user that they are in a restricted place that their role doesn't allow access to
      if (navStatus === EnumNavStatus.DENIED) {
        showErrorNotification(
          'Your role restricts you from accessing this feature. If you think that this is in error please contact your administrator'
        );
        navigate(-1);
        return;
      }

      if (navStatus === EnumNavStatus.NOT_FOUND) {
        showErrorNotification("This route doesn't exist");
        navigate(-1);
        return;
      }
    }

    if (!auth.isLoggedIn && !routeIsAllowed(location.pathname)) {
      navigate(getLoginPath({}));
    }
  }, [auth.isLoggedIn, location.pathname]);

  // Added an 'isTest' check here so that we can render all of the features during testing
  useEffect(() => {
    if (!auth.isProUser && !auth.isLoading && !isTest) {
      if (disallowedRoutes.includes(location.pathname)) {
        navigate(getProVersionPagePath({}));
      }
    }
  }, [location.pathname, auth.isProUser, auth.isLoading]);

  const generatedRoutes = useRoutes(routes);
  return (
    <Suspense fallback={<LoadingText classNames="text-2xl" />}>
      {generatedRoutes}
    </Suspense>
  );
};
