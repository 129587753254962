import * as BrowserSentry from '@sentry/browser';
import { browserTracingIntegration, replayIntegration } from '@sentry/react';
import { getApiVersion } from './getApiVersion';

type SentryConfig = {
  dsn: string;
  debug: boolean;
  tracesSampleRate: number;
  replaysSessionSampleRate: number;
  replaysOnErrorSampleRate: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  integrations?: any[];
};

const sentryConfig: SentryConfig = {
  dsn: 'https://20aa0e597d6146ea8d91b9608432072e@o4505601866727424.ingest.sentry.io/4505606850674688',
  debug: true,
  tracesSampleRate: 0.5,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
};

const replayConfig = {
  maskAllText: false,
  blockAllMedia: false,
};

export async function initSentry() {
  const apiVersion = await getApiVersion();

  if (import.meta.env.VITE_CI_COMMIT_BRANCH === 'master') {
    try {
      if (window.location.hostname === 'oneracehub.paceteq.net') {
        sentryConfig.integrations = [
          browserTracingIntegration(),
          replayIntegration(replayConfig),
        ];

        BrowserSentry.init({
          ...sentryConfig,
        });

        BrowserSentry.getCurrentScope().setTag('apiversion', apiVersion);
      }
    } catch (error) {
      console.error('Error initializing Sentry (client):', error);
    }
  }
}
