import { openDB } from 'idb';
import { get } from 'lodash';
import { assertUnreachable } from './features/common/utils/typeGuards';
import { EnumFlexLayoutConfigIntendedFor } from './generated/graphql';

// we could have a version number generated from the package index ORH version
const idbVersion = 4;

// helper function to return stores for flex layout intentions
export const returnStoreNameForFlexLayoutIntention = (
  intendedFor: EnumFlexLayoutConfigIntendedFor
): AvailableObjectStoresType | undefined => {
  switch (intendedFor) {
    case EnumFlexLayoutConfigIntendedFor.KPI:
      return 'kpiDashboard';
    case EnumFlexLayoutConfigIntendedFor.SETUPOVERVIEW:
      return 'carOverview';
    case EnumFlexLayoutConfigIntendedFor.SIMULATION:
      return 'simulation';
    case EnumFlexLayoutConfigIntendedFor.RUNSHEET:
      return 'protocolDashboard';
    case EnumFlexLayoutConfigIntendedFor.SETUPSHEET:
    case EnumFlexLayoutConfigIntendedFor.SETUPSANDBOX:
      return undefined;
    default:
      assertUnreachable(intendedFor);
  }
};

// register every object store we want to save here; add new things here
export const ObjectStores = {
  carOverview: 'carOverview',
  customSimulationSettings: 'customSimulationSettings',
  kpiDashboard: 'kpiDashboard',
  protocolDashboard: 'protocolDashboard',
  simulation: 'simulation',
};

export type AvailableObjectStoresType = keyof typeof ObjectStores;

// open the index DB and upgrade if we need a new version
// no top level await so will return a promise here
export const orhIdb = openDB('OneRacehub', idbVersion, {
  upgrade(db) {
    for (const store of Object.keys(ObjectStores)) {
      if (!db.objectStoreNames.contains(store)) {
        db.createObjectStore(store);
      }
    }
  },
});

// function to be used with individual keys
export const loadDatabase = <IIndexDBData extends object>(
  objectStore: AvailableObjectStoresType,
  requestKeys: Extract<keyof IIndexDBData, string>[]
) => {
  return async () => {
    const db = await orhIdb;
    const promiseArray = requestKeys.map((key) =>
      db.get(objectStore, key).then((value) => {
        return { key: key, value };
      })
    );
    const loadedData = await Promise.all(promiseArray);
    const output = loadedData.reduce((obj, item) => {
      return { ...obj, ...{ [item.key]: item.value } };
    }, {});

    return output as IIndexDBData;
  };
};

// function to get the full data from the store, as indexDB is performance limited
// when opening a lot of transactions it might be better to get all data in a
// single transaction
export const loadFullObjectStore = <IIndexDBData extends object>(
  objectStore: AvailableObjectStoresType
) => {
  return async () => {
    const output = orhIdb.then((db) =>
      db
        .getAllKeys(objectStore)
        .then((keyArray) =>
          db
            .getAll(objectStore)
            .then((data) =>
              Object.fromEntries(
                keyArray.map((key, index) => [key, data[index]])
              )
            )
        )
    );
    return output as IIndexDBData;
  };
};

export const saveFullObjectStore = <IIndexDBData extends object>(
  objectStore: AvailableObjectStoresType,
  data: IIndexDBData
) => {
  try {
    orhIdb.then((db) => {
      Object.keys(data).map((key) => db.put(objectStore, get(data, key), key));
    });
  } catch (err) {
    console.error('Error saving to IndexDB: ', err);
  }
};

// If this now could somehow tell me that I am not allowed certain things...
export const saveKeyToObjectStore = async <IIndexDBData extends object>(
  objectStore: keyof typeof ObjectStores,
  data: Partial<IIndexDBData>,
  key: Extract<keyof IIndexDBData, string>
) => {
  try {
    const db = await orhIdb;
    await db.put(objectStore, data, key);
  } catch (err) {
    console.error('Error saving to IndexDB: ', err);
  }
};

export const exportDbToJson = async (filename?: string) => {
  const db = await orhIdb;
  const promiseArray = Object.entries(ObjectStores).map(
    async ([key, value]) => {
      const data = await db.getAll(value);
      return [[key], data];
    }
  );
  const loadedData = await Promise.all(promiseArray);
  const a = document.createElement('a');
  const file = new Blob([
    JSON.stringify({
      ...Object.fromEntries(loadedData),
    }),
  ]);

  const date = new Date();
  a.href = URL.createObjectURL(file);
  a.download = `${filename ?? 'oneracehub-debug'}-${date.toISOString()}.json`;
  a.click();
};

export const clearOneRacehubDb = () => {
  indexedDB.deleteDatabase('OneRacehub');
};
