import { match } from 'path-to-regexp';
import { Location } from 'react-router-dom';
import { EnumPersonRole } from '~/generated/graphql';
import { logger } from '../logger';
import { hasRequiredRoles } from '../loginChecks';
import { getFlattenedRoutesAndAddRoleRestrictionsFromSideBar } from './flattenRoutesAndAddRoleRestrictionsFromSideBar';

export enum EnumNavStatus {
  ALLOWED = 'ALLOWED',
  DENIED = 'DENIED',
  NOT_FOUND = 'NOT_FOUND',
}

// this allows comparison between dynamic url elements (ie. '/:id') and their generated value
// e.g. admin/persons/:id --matches-- admin/persons/12gh3h4j4jjjjffff23
const matchPath = (routePath: string, currentPath: string): boolean => {
  const matcher = match(routePath, { decode: decodeURIComponent });
  return matcher(currentPath) !== false;
};

export const restrictRoutesAndFeatures = (
  location: Location,
  role?: EnumPersonRole
): EnumNavStatus => {
  // these routes can't be derived from the sidebar, but don't need protecting anyway
  if (
    location.pathname === '/' ||
    location.pathname === '/profile' ||
    location.pathname === '/logout' ||
    location.pathname === '/home' ||
    location.pathname === '/auth/login' ||
    location.pathname === '/manage-logins'
  )
    return EnumNavStatus.ALLOWED;

  const currentRoute =
    getFlattenedRoutesAndAddRoleRestrictionsFromSideBar().find(
      // check the current url location against compiled routes objetcs
      (route) => matchPath(route.path, location.pathname)
    );

  if (currentRoute) {
    // if no route restrictions are present return TRUE
    if (
      currentRoute.restrictToRoles &&
      currentRoute.restrictToRoles.length < 1
    ) {
      logger.log('no restrictions:', currentRoute.path);
      return EnumNavStatus.ALLOWED;
    }
    // if user is an admin then don't restrict route
    if (role === EnumPersonRole.ADMIN) {
      logger.log('user can access:', currentRoute.path);
      return EnumNavStatus.ALLOWED;
    }
    // if user role isn't in allowed roles return FALSE
    if (!hasRequiredRoles(currentRoute.restrictToRoles)) {
      logger.log('restriction prevented access:', currentRoute.path);
      return EnumNavStatus.DENIED;
    }
    // if no restrictions and route exists return TRUE
    logger.log('user can access:', currentRoute.path);
    return EnumNavStatus.ALLOWED;
  }

  // if route doesn't exist return FALSE
  logger.log('No current route', location.pathname);
  return EnumNavStatus.NOT_FOUND;
};
