import { RouteObject } from 'react-router-dom';
import { EnumPersonRole } from '~/generated/graphql';
import {
  FlattenedSideBarRouteObject,
  getFlattenedSidebarFeatureRoutes,
} from '../../security/RouteLookup';
// @ts-expect-error types are ignore because of the dynamic imports
import routes from '~react-pages';

// this flattens route objects from react-router-dom and combines them with the role restrictions defined in the sidebar thus maintaining a single source of truth for role permissions
const flattenRoutesAndAddRoleRestrictionsFromSideBar = (
  routesToTransform: RouteObject[],
  parent?: RouteObject & {
    restrictToRoles: EnumPersonRole[] | undefined;
    baseURL: string;
  }
): {
  path: string;
  restrictToRoles: EnumPersonRole[] | undefined;
  baseURL: string;
}[] => {
  // start the flatMap of routes
  return routesToTransform.flatMap((route: RouteObject) => {
    // combine the parent path and current path to generate route string for nested routes i.e. PARENT:"/admin" + CHILD:"/persons" = admin/persons etc
    const path = `${parent ? parent.path : ''}/${route.path}`.replace(
      /\/+/g,
      '/'
    );

    // search the array generated from the sidebar routes to get any role restrictions and add them to the route object
    const foundRoute = getFlattenedSidebarFeatureRoutes().find(
      (flattenedRoute) => {
        return (
          flattenedRoute.path.replaceAll('/', '') ===
            path.replaceAll('/', '') || flattenedRoute.path.includes(path)
        );
      }
    );

    const baseURL = foundRoute?.baseURL;
    const restrictToRoles =
      foundRoute?.restrictToRoles ?? parent?.restrictToRoles;

    const current = {
      path,
      restrictToRoles: restrictToRoles ?? parent?.restrictToRoles ?? [],
      baseURL: baseURL ?? '',
    };

    // if there are any children then repeat the process recursively, if not return then set children as an empty array
    const children = route.children
      ? flattenRoutesAndAddRoleRestrictionsFromSideBar(route.children, current)
      : [];
    // return current route objects with all children flatMapped
    return [current, ...children];
  });
};

let memoizedFlattenedSidebarFeatureRoutes:
  | FlattenedSideBarRouteObject[]
  | null = null;

export const getFlattenedRoutesAndAddRoleRestrictionsFromSideBar =
  (): FlattenedSideBarRouteObject[] => {
    if (!memoizedFlattenedSidebarFeatureRoutes) {
      memoizedFlattenedSidebarFeatureRoutes =
        flattenRoutesAndAddRoleRestrictionsFromSideBar(routes);
    }
    return memoizedFlattenedSidebarFeatureRoutes;
  };
