import { ReactNode, useId } from 'react';
import { ITooltipWrapper, Tooltip } from 'react-tooltip';

interface CustomTooltipWrapperProps {
  content?: string | null;
  html?: string | null;
  jsx?: ReactNode | null;
  children: ReactNode;
  place?: ITooltipWrapper['place'];
  testid?: string;
  positionStrategy?: ITooltipWrapper['positionStrategy'];
}

const CustomTooltipWrapper = ({
  content,
  html,
  jsx,
  children,
  place,
  testid,
  positionStrategy = 'absolute',
}: CustomTooltipWrapperProps) => {
  const toolTipId = useId();

  if (typeof content === 'string' || typeof html === 'string') {
    return (
      <>
        <Tooltip
          id={toolTipId}
          place={place}
          className="max-w-[20rem] whitespace-pre-wrap normal-case dark:bg-gray-100 dark:text-gray-800"
          positionStrategy={positionStrategy}
        />
        <span
          data-tooltip-id={toolTipId}
          data-tooltip-content={content || undefined}
          data-tooltip-html={html || undefined}
          className="hover:cursor-pointer"
          data-testid={testid}
        >
          {children}
        </span>
      </>
    );
  }

  if (jsx) {
    return (
      <>
        <Tooltip
          id={toolTipId}
          place={place}
          className="max-w-[20rem] whitespace-pre-wrap dark:bg-gray-100 dark:text-gray-800"
          data-testid={testid}
        >
          {jsx}
        </Tooltip>
        <span data-tooltip-id={toolTipId}>{children}</span>
      </>
    );
  }

  return <>{children}</>;
};

export default CustomTooltipWrapper;
