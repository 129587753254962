import { EnumPersonRole } from '../../../generated/graphql';
import {
  aiAndMl,
  allRolesExceptDriver,
  configuration,
  manager,
  operations,
  partsAndSpecs,
  SidebarChild,
  SideBarNavigation,
} from '../components/Sidebar/components/SidebarNavigationLinks';

export type FlattenedSideBarRouteObject = {
  path: string;
  baseURL: string;
  restrictToRoles: EnumPersonRole[] | undefined;
};

// Route Lookup
// here we are getting a list of routes and their restrictions from the sidebar component
// we are flattening it so that we can splice it with the routes generated by react-router-dom later - see 'flattenRoutesAndAddRoleRestrictionsFromSideBar.tsx'
// this will allow us to restrict nested routes/urls dynamically by defining them in the sidebar

// iterating through all sidebar links to combine all routes and restrictions defined there into a single array
const featuresAndRoutesFromSidebarArray = [
  ...operations().children,
  ...manager().children,
  ...aiAndMl().children,
  ...partsAndSpecs().children,
  ...configuration().children,
];

const flattenChildren = (
  sideBarChild: SidebarChild[],
  parent: FlattenedSideBarRouteObject
): FlattenedSideBarRouteObject[] => {
  return sideBarChild.flatMap((child: SidebarChild) => {
    const current = {
      path: child.baseURL ?? child.link ?? parent.baseURL,
      restrictToRoles: child.restrictToRoles ?? parent.restrictToRoles,
      baseURL: child.baseURL ?? parent.baseURL,
    };
    const children = child.children
      ? flattenChildren(child.children, current)
      : [];
    if (children.length > 1) return [...children];

    return current;
  });
};

// this flattens sidebar routes into a single array to make route and role comparison easier
const flattenSideBarLinks = (
  routes: SideBarNavigation[]
): FlattenedSideBarRouteObject[] => {
  return routes.flatMap((route) => {
    const current = {
      path: route.href.length > 0 ? route.href : route.baseURL,
      restrictToRoles: route.restrictToRoles,
      baseURL: route.baseURL,
    };

    // Ensure children are always flattened properly
    const children = route.children
      ? flattenChildren(route.children, current)
      : [];

    // if a route has children, then return only those, since the route url will be compared at the child level
    if (children.length > 1) return [...children];

    // if no children then we've reached the level at which comparing it's url to 'location.pathname' is useful
    return current;
  });
};

let memoizedFlattenedSidebarFeatureRoutes:
  | FlattenedSideBarRouteObject[]
  | null = null;

export const getFlattenedSidebarFeatureRoutes =
  (): FlattenedSideBarRouteObject[] => {
    if (!memoizedFlattenedSidebarFeatureRoutes) {
      memoizedFlattenedSidebarFeatureRoutes = [
        ...flattenSideBarLinks(featuresAndRoutesFromSidebarArray),
        {
          path: '/parts/:id',
          restrictToRoles: allRolesExceptDriver,
          baseURL: '/parts',
        },
        {
          path: '/assemblies/:id',
          restrictToRoles: allRolesExceptDriver,
          baseURL: '/assemblies',
        },
        {
          path: '/admin/track-layout',
          restrictToRoles: [EnumPersonRole.ADMIN, EnumPersonRole.MANAGEMENT],
          baseURL: '/admin',
        },
        {
          path: '/jobs/job-list-templates',
          restrictToRoles: [
            EnumPersonRole.ADMIN,
            EnumPersonRole.CAR_ENGINEER,
            EnumPersonRole.CHIEF_ENGINEER,
            EnumPersonRole.MANAGEMENT,
            EnumPersonRole.WORKSHOP_MANAGEMENT,
          ],
          baseURL: '/admin',
        },
      ];
    }
    return memoizedFlattenedSidebarFeatureRoutes;
  };
